import { Controller } from "stimulus";
import { elementIsVisibleInViewport } from "../../helpers/viewport";
import { throttle } from "../../util";
import { VIEW_COIN_INSIGHTS } from "../../mixpanel_config";
import { unconditionalTrackEvent } from "../../analytics";
import { EventMixpanelInitLoaded, EventPerformanceCookieConsentChanged } from "../../events";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static targets = ["coinInsightsContainer", "scrollContainer", "scrollLeftButton", "scrollRightButton", "coinInsightCard"];

  static values = {
    coinName: String
  }

  connect() {
    import("@lottiefiles/lottie-player");

    this.smallScreenXOffset = 7.5;
    this.scrollIndex = 0;
    this.cardWidth = this.coinInsightCardTargets[0]?.offsetWidth;
    this.containerScrollLeft = this.scrollContainerTarget.scrollLeft;
    this.scrollContainerLength = this.coinInsightCardTargets.length;
    this.containerOffsetWidth = this.scrollContainerTarget.offsetWidth;
    this.hasSentViewCoinInsightsEvent = false

    this._setupScrollButtons();

    // Note: Some browsers don't support scrollend, eg. Safari,
    // so we use scroll + timeout to refresh the buttons.
    if (this._isScrollEndSupported()) {
      this.scrollContainerTarget.addEventListener("scrollend", (event) => {
        this._handleScroll();
      });
    } else {
      this.scrollContainerTarget.addEventListener("scroll", (event) => {
        setTimeout(() => { this._handleScroll(); }, 1000);
      });
    }

    window.addEventListener(EventMixpanelInitLoaded, (e) => {
      this._detectCoinInsightInViewport();
      window.addEventListener("scroll", this._detectCoinInsightInViewport.bind(this));
    });
  }

  scrollLeft() {
    if (this._isSmallScreen()) {
      this._scrollByX(-(this.containerOffsetWidth + this.smallScreenXOffset));
    } else {
      this._scrollByX(-this.containerOffsetWidth / 2);
    }
  }

  scrollRight() {
    if (this._isSmallScreen()) {
      this._scrollByX(this.containerOffsetWidth + this.smallScreenXOffset);
    } else {
      this._scrollByX(this.containerOffsetWidth / 2);
    }
  }

  _handleScroll() {
    this._updateScrollIndex();
    this._setupScrollButtons();
  }

  _updateScrollIndex() {
    if (this.containerScrollLeft === undefined || this.cardWidth === undefined) { return; }

    const newScrollLeft = this.scrollContainerTarget.scrollLeft;
    const scrollOffset = newScrollLeft - this.containerScrollLeft;
    this.scrollIndex = Math.floor(scrollOffset / this.cardWidth);
  }

  _setupScrollButtons() {
    if (this.scrollIndex === undefined) { return; }

    this._showElements([this.scrollLeftButtonTarget, this.scrollRightButtonTarget]);

    // [small screen] hide left and right buttons if not scrollable
    if (this._isSmallScreen() && this.scrollContainerLength <= 1) {
      this._hideElements([this.scrollLeftButtonTarget, this.scrollRightButtonTarget]);
      return;
    }

    // [bigger screen] hide left and right buttons if not scrollable
    if (!this._isSmallScreen() && this.scrollContainerLength <= 3) {
      this._hideElements([this.scrollLeftButtonTarget, this.scrollRightButtonTarget]);
      return;
    }

    // hide left button if at leftmost position
    if (this.scrollIndex === 0) {
      this._hideElements([this.scrollLeftButtonTarget]);
      return;
    }

    // [small screen] hide right button if at rightmost position
    if (this._isSmallScreen() && this.scrollIndex === this.scrollContainerLength - 1) {
      this._hideElements([this.scrollRightButtonTarget]);
      return;
    }

    // [bigger screen] hide right button if at rightmost position
    if (!this._isSmallScreen() && this.scrollContainerLength - this.scrollIndex <= 3) {
      this._hideElements([this.scrollRightButtonTarget]);
    }
  }

  _scrollByX(scrollWidth) {
    this.scrollContainerTarget.scrollBy(scrollWidth, 0);
  }

  _isSmallScreen() {
    return window.innerWidth < 448;
  }

  _showElements(elements) {
    elements.forEach(e => e.classList.remove("!tw-hidden"));
  }

  _hideElements(elements) {
    elements.forEach(e => e.classList.add("!tw-hidden"));
  }

  // Ref: https://stackoverflow.com/questions/2877393/detecting-support-for-a-given-javascript-event?rq=3
  _isScrollEndSupported() {
    return ("onscrollend" in window);
  }

  _detectCoinInsightInViewport() {
    if (elementIsVisibleInViewport(this.coinInsightsContainerTarget)) {
      if (!this.hasSentViewCoinInsightsEvent) {
        unconditionalTrackEvent(VIEW_COIN_INSIGHTS, { coin_name: this.coinNameValue })
        this.hasSentViewCoinInsightsEvent = true
      }
    }
  }
}
